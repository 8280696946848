<template>
  <div
    class="containerFS_Dialog"
    v-show="showDialog"
  >
    <div
      class="backgroundFS_Dialog
      backgroundBingo
      zoomInAnimation"
    >
      <div class="closeButton">
        <div class="closeImage" v-on:click="hideDialog" />
      </div>
      <div class="containerFS">
        <div class="titleFS_Dialog text-large colorBlack">
          {{title}}
        </div>
        <div class="messageFS_Dialog text-subtitle-large colorBlack">
          {{message}}
        </div>
        <div
          class="buttonFullscreen"
          v-on:click="goToFullscreen"
        />
      </div>
    </div>
  </div>
</template>

<script lang="js">
import APP_TEXTS from '../languages/language_PT';

export default {
  name: 'DialogFS',
  data() {
    return {
      title: APP_TEXTS.fullscreenTitle,
      message: APP_TEXTS.fullscreenMessage,
      showDialog: true,
      isApp: false,
    };
  },
  beforeMount() {
    this.addEventListener();
  },
  mounted() {
    this.leaveFullscreenMode();
    this.isApp = this.$store.getters.getIsApp;
    this.showDialog = true && !this.isApp;
  },
  methods: {
    goToFullscreen() {
      if (document.documentElement && document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
          .catch(() => {
            this.hideDialog();
          });
      }
      this.hideDialog();
    },
    hideDialog() {
      this.showDialog = false;
    },
    addEventListener() {
      document.addEventListener('keydown', (e) => {
        e.preventDefault();
        if (e.keyCode === 13 || e.key === 'Enter' || e.keyCode === 122 || e.key === 'F11') {
          this.goToFullscreen();
        }

        if (e.keyCode === 24 || e.key === 'Escape') {
          this.hideDialog();
        }
      }, false);

      document.addEventListener('fullscreenchange', () => {
        const fullscreenElement = document.fullscreenElement || document.mozFullScreenElement
        || document.webkitFullscreenElement || document.msFullscreenElement;

        if (fullscreenElement) {
          this.enterFullscreenMode();
        } else {
          this.leaveFullscreenMode();
        }
      });
    },
    leaveFullscreenMode() {
      this.showDialog = true && !this.isApp;

      const bolilleroContainer = document.getElementsByClassName('spriteMain');
      if (bolilleroContainer && bolilleroContainer.length) {
        const istvBox = this.$store.getters.getChannel;
        const top = (istvBox) ? '-15%' : '-24%'; // '-39%';
        const left = (istvBox) ? '-15%' : '-13%'; // '-69%';

        bolilleroContainer[0].style.top = top;
        bolilleroContainer[0].style.left = left;
      }
    },
    enterFullscreenMode() {
      const bolilleroContainer = document.getElementsByClassName('spriteMain');

      if (bolilleroContainer && bolilleroContainer.length) {
        const istvBox = this.$store.getters.getChannel;
        const top = (istvBox) ? '-6%' : '-15%'; // '-33%';
        const left = (istvBox) ? '-15%' : '-13%'; // '-69%';

        bolilleroContainer[0].style.top = top;
        bolilleroContainer[0].style.left = left;
      }

      this.hideDialog();
    },
  },
};
</script>

const languagePT = {
  AcceptingBets: 'FAÇA SUA APOSTA!',
  accumulated: 'Acumulado',
  attention: 'Atenção',
  balls: 'Bolas',
  bingo: 'Bingo',
  currentRaffle: 'rodada atual',
  currentTime: 'Hora Atual',
  data: 'Dados da',
  date: 'Data e Hora',
  fullscreenMessage: 'pressione a tecla Enter ou clique no botão',
  fullscreenTitle: 'Ir para tela completa',
  futurePlaysNotAvailable: 'Próximas Rodadas não disponível',
  previousRafflesNotAvailable: 'Últimas rodadas não disponível',
  gameStopped: 'Sorteio parado',
  hour: 'Hora',
  linea: 'Linha',
  nextRaffle: 'Próxima Rodada',
  nextRaffles: 'Próximas Rodadas',
  nextRaffleIn: 'Próxima Rodada em',
  NoMoreBets: 'APOSTAS ENCERRADAS',
  previousRafflesHeader: 'N° Cart. - Nome Ganhador',
  number: 'Número',
  participants: 'Participantes',
  PlayClosed: 'Sorteio fechado',
  prize: 'Prêmio',
  prizeNotAvailable: 'Prêmio não disponível',
  previousRaffles: 'Últimas Rodadas',
  raffle: 'Sorteio',
  RaffleComplete: 'Sorteio concluído',
  raffled: 'Sorteadas',
  raffleToStart: 'Preparando Rodada para começar',
  preparingRaffle: 'Preparando sorteio',
  Raffling: 'Jogando',
  tableInMaintenance: 'Sistema indisponível',
  urlParamsInvalid: 'Parâmetros da url inválido',
  ticketId: 'Cartela N°',
  typeChannel: 'Selecione o seu tipo de dispositivo',
  typeComputer: 'Computador',
  typeTv: 'Tv Box ou Tv Smart',
  until: 'Até',
  value: 'Valor',
  winners: 'Vencedores',
  notWinners: 'Não há vencedores',
  wonPrize: 'Eles ganharam prêmio',
};

export default languagePT;

<template>
  <div class="idleContainer backgroundBingo">
    <div class="progressContainer">
      <v-progress-circular
        :size="70"
        :width="7"
        color="purple"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>

export default {
  name: 'IdleApp',
  data() {
    return {
    };
  },
  beforeMount() {
  },
  methods: {
  },
};
</script>

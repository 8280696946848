<template>
  <div class="futurePlaysContainer backgroundBingo">
    <div class="preRaffleTitle">
      <div class="text-title-big text-bold text-center colorOrange">
        {{getPhrase('previousRaffles')}}
      </div>
    </div>
    <div
      id="previousRaffleInfo"
      class="previousRaffleInfo"
    >
      <table
        id="tablePreviousRaffle"
        class="tablePreviousRaffle"
        v-if="previousRaffles.length > 0"
      >
        <col width="16%" />
        <col width="15%" />
        <col width="23%" />
        <col width="23%" />
        <col width="23%" />
        <thead class="tableHeader">
          <tr>
            <td
              v-for="item in headers"
              :key="item.name"
              class="text-subtitle-3 colorWhite text-center"
            >
              {{ item.text }}
            </td>
            <td
              v-for="(item, index) in prizeToShow"
              :id="index"
              :key="item.name"
              class="text-subtitle-3 colorWhite text-center"
            >
              <span class="text-subtitle-3 colorWhite text-center">
                {{ String(item.name).toUpperCase() }}
              </span>
              <span class="prizeFuturePlays text-subtitle-medium colorWhite text-center">
                {{getPhrase('previousRafflesHeader')}}
              </span>
            </td>
          </tr>
        </thead>
        <tbody id="previousRafflesData">
          <tr
            v-for="(play, index) in previousRaffles"
            :key="index"
            :class='(index % 2) ? "tableRowLight textFontNormal" : "tableRowDark textFontNormal"'
          >
            <td>
              <span class="text-subtitle-3 colorWhite text-center">
                {{ play.matchName }}
              </span>
            </td>
            <td class="text-subtitle-3 colorWhite text-center">
              <span class="prizeFuturePlays text-subtitle-large colorOrange text-center">
                {{play.date}}
              </span>
              <span class="prizeFuturePlays text-subtitle-medium colorOrange text-center">
                {{play.startTime}}
              </span>
            </td>
            <td
              v-for="(prize, prizeIndex) in play.prizeToShow"
              :key="prizeIndex"
            >
              <span
                v-for="(winner, winnerIndex) in prize.winners"
                :key="winnerIndex"
                class="winnerPreviousRaffles text-subtitle-large colorWhite text-center"
              >
                {{ `${winner.cardId} - ${winner.winnerId}`}}
              </span>
              <span
                v-if="prize.winners.length === 0"
                class="text-subtitle-large colorWhite text-center"
              >
                {{getPhrase('notWinners')}}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="progressContainer"
        v-else-if="!showMessageNotFound"
      >
        <v-progress-circular
          :size="100"
          :width="7"
          color="white"
          indeterminate
        ></v-progress-circular>
      </div>
      <div
        v-else
        class="text-subtitle-large textLeft colorBlack"
      >
        {{getPhrase('previousRafflesNotAvailable')}}
      </div>
    </div>
  </div>
</template>

<script>
import APP_UTILS from '../config/app_utils';
import APP_TEXTS from '../languages/language_PT';
/* eslint-disable no-console */
/* eslint-disable no-plusplus */

export default {
  name: 'PreviousRaffles',
  data() {
    return {
      headers: [
        { text: APP_TEXTS.raffle, align: 'start' },
        { text: APP_TEXTS.date, align: 'center' },
      ],
      previousRafflesData: [],
      prizeToShow: [],
      errorDataFuturePlays: 0,
      showMessageNotFound: false,
      firstElementTimestamp: '',
      errorData_timeoutId: null,
      validateVisible_timeoutId: null,
      isValidated: false,
    };
  },
  computed: {
    previousRaffles() {
      const previous = this.$store.getters.previousRaffles;
      const previousRaffles = this.previousRafflesParse(previous);
      return previousRaffles;
    },
  },
  methods: {
    previousRafflesParse(playsData) {
      if (playsData?.length && playsData[0].matchDate === this.firstElementTimestamp) {
        return this.previousRafflesData;
      }
      const previousRaffles = [];

      for (let index = 0; index < playsData.length; index++) {
        const play = playsData[index];
        const timestampValid = ((new Date(play.matchDate)).getTime() > 0);
        let timeDraw = '00:00:00';
        let dateDraw = '00/00/0000';
        if (timestampValid) {
          const date = new Date(play.matchDate);

          const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
          };

          [dateDraw, timeDraw] = new Intl.DateTimeFormat('pt-BR', options).format(date).split(',');
        }

        const prizeToShow = play.winnersByPrize;
        this.prizeToShow = (prizeToShow.length) ? prizeToShow : [];

        const newPlay = {
          cardValue: APP_UTILS.formatCurrency(play.cardValue),
          date: dateDraw,
          matchDate: play.matchDate,
          matchName: `${play.matchId} - ${play.matchName}`,
          prizeToShow,
          startTime: timeDraw,
        };

        previousRaffles.push(newPlay);
      }

      this.setError(previousRaffles);

      this.previousRafflesData = previousRaffles;
      this.firstElementTimestamp = (previousRaffles.length && previousRaffles[0].matchDate) || '';
      this.validateVisibleElements();
      return previousRaffles;
    },
    setError(futurePlays) {
      if (futurePlays.length) {
        this.errorDataFuturePlays = 0;
        this.showMessageNotFound = false;
      } else {
        this.errorDataFuturePlays += 1;
        this.showMessageNotFound = (this.errorDataFuturePlays >= 10);
        this.validateError();
      }
    },
    validateError() {
      const timeout = 1000;
      clearTimeout(this.errorData_timeoutId);
      this.errorData_timeoutId = null;

      if (!this.prizeToShow.length && this.errorDataFuturePlays < 10) {
        this.errorData_timeoutId = setTimeout(this.validateError, timeout);
      }
    },
    getPhrase(phraseId, lowerCase) {
      const phrase = APP_UTILS.getPhrase(phraseId, lowerCase);
      return phrase;
    },
    setFormatCurrency(value) {
      return APP_UTILS.formatCurrency(value);
    },
    validateVisibleElements() {
      const component = document.getElementById('previousRafflesComponent');
      const container = document.getElementById('previousRaffleInfo');
      const elementsContainer = document.getElementById('previousRafflesData');
      const elements = (elementsContainer && elementsContainer.children) || [];

      clearTimeout(this.validateVisible_timeoutId);
      this.validateVisible_timeoutId = null;

      if (component && component.style.display === 'none') {
        this.validateVisible_timeoutId = setTimeout(this.validateVisibleElements, 1000);
        return;
      }

      for (let iElement = elements.length - 1; iElement > 0; iElement--) {
        const elem = elements[iElement];
        const isVisible = this.isVisibleElements(elem, container);

        if (!isVisible) {
          elem.remove();

          if (this.previousRafflesData.length >= iElement) {
            this.previousRafflesData.splice(iElement, 1);
          }
        }
      }

      this.isValidated = true;
    },
    isVisibleElements(element, container) {
      if (!element || !container) { return false; }
      if (!element.getBoundingClientRect || !container.getBoundingClientRect) { return false; }

      const trRect = element.getBoundingClientRect();
      const contRect = container.getBoundingClientRect();

      return trRect.bottom < contRect.bottom;
    },
  },
};
</script>

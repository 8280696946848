<template>
  <div class="containerCenter">
    <div class="containerCenterTop">
      <div class="containerBallsDrawed">
        <div class="text-subtitle text-bold colorOrange ma-2">
          {{getPhrase('balls')}}
          <br />
          {{getPhrase('raffled')}}
        </div>
        <div class="text-xlarge text-bold colorWhite">
          {{totalBallsDisplayed}}
        </div>
      </div>
      <div class="containerAccumulated">
        <div :class="lockJackpot ? 'lockedJackpot': ''">
          <div :class="lockJackpot ? 'iconLocked lockedVisible': 'iconLocked lockedHidden'" />
        </div>
        <v-col cols="12">
          <div class="textJackpot text-subtitle-large text-bold colorWhite">
            <div class="centerAbsolute">
              {{getPhrase('accumulated')}}
            </div>
          </div>
          <div class="valueJackpot text-large text-bold colorOrange">
            <div class="centerAbsolute">
              {{startGame && startGame.accumulatedPrize}}
            </div>
          </div>
          <div class="containerAccRight">
            <div class="textUntil text-subtitle text-bold colorWhite">
              {{getPhrase('until')}}
            </div>
            <div class="valueUntil text-medium text-bold colorWhite">
              {{startGame && startGame.accumulatedPrizeBalls}}
            </div>
            <div class="textUntil text-subtitle text-bold colorWhite">
              {{getPhrase('balls')}}
            </div>
          </div>
        </v-col>
      </div>
      <div class="containerInfoBingo">
        <div class="containerDate">
          <div class="text-subtitle text-bold colorWhite pt-1">
            {{getPhrase('currentTime')}}
          </div>
          <div class="text-medium text-bold colorOrange pt-1">
            {{date}}
          </div>
        </div>
        <div class="containerDate">
          <div class="text-subtitle text-bold colorWhite pt-1">
            {{getPhrase('nextRaffle')}}
          </div>
          <div class="text-medium text-bold colorOrange pt-1">
            {{nextDateRaffle}}
          </div>
        </div>
      </div>
    </div>
    <div class="containerCenterBottom">
      <div class="containerBoardIdle">
        <div id="boardContainer"
          v-for="(ball, index) in ballsInBoard"
          v-bind:key="index"
          class="itemBoardContainer"
          >
            <div class="d-line idleBall" id="bingoBallIdle">
              <div :id="'idleBall-' + index">
                <div :id="'idleNumber-' + index"
                  :class="`boardIdleText ${ballsInBoard[index]}`"
                >
                  {{(index >= 9) ? (index + 1) : (`0${index + 1}`)}}
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-plusplus */
import APP_UTILS from '../config/app_utils';

export default {
  name: 'Bingo',
  data() {
    return {
      ballsInBoard: [],
      date: '00:00:00',
      lockJackpot: false,
      nextRaffle: '00:00:00',
      nextRaffleTimestamp: null,
      totalBallsInBoard: 90,
      totalBallsDisplayed: 0,
      urlGridImage: [],
    };
  },
  beforeMount() {
    this.loadImage();
    this.emptyBoard();
    this.updateDate();
  },
  computed: {
    startGame() {
      const dataGame = this.$store.state.startGame;
      const endGame = this.$store.getters.showCompletedGame;
      this.showBallsResultsInBoard(dataGame.idleResults, endGame);
      this.validateLockJackpot(dataGame);

      return dataGame;
    },
    currentRaffleData() {
      return this.$store.getters.currentRaffleData;
    },
    nextDateRaffle() {
      const nextDateRaffle = this.$store.getters.nextDraw;

      return this.setNextDateDraw(nextDateRaffle);
    },
  },
  methods: {
    showBallsResultsInBoard(results, endGame) {
      if (!results.length || endGame) {
        this.emptyBoard();
        return false;
      }

      for (let index = 0; index < results.length; index++) {
        const id = results[index] - 1;
        if (id < this.ballsInBoard.length && this.ballsInBoard[id] === 'emptyGrid') {
          const indexUrl = id % 10;
          const urlImage = this.urlGridImage[indexUrl];

          if (urlImage !== '') {
            this.ballsInBoard[id] = urlImage;
            this.totalBallsDisplayed++;
          }
        }
      }
      return true;
    },
    loadImage() {
      const totalImages = 10;
      const arrayUrl = [];
      let url = false;

      for (let index = 0; index < totalImages; index++) {
        url = `selectedGrid_${index}`;
        arrayUrl.push(url);
      }
      this.urlGridImage = arrayUrl;
      this.lockJackpot = true;
    },
    emptyBoard() {
      this.ballsInBoard = [];
      this.totalBallsDisplayed = 0;

      for (let i = 0; i < this.totalBallsInBoard; i++) {
        this.ballsInBoard.push('emptyGrid');
      }
    },
    updateDate() {
      const timeout = 1000;
      let h = new Date().getHours();
      let m = new Date().getMinutes();
      let s = new Date().getSeconds();
      h = (h >= 10) ? h : `0${h}`;
      m = (m >= 10) ? m : `0${m}`;
      s = (s >= 10) ? s : `0${s}`;
      const date = `${h}:${m}:${s}`;
      this.date = date;
      this.currentDateId = setTimeout(() => {
        clearTimeout(this.currentDateId);
        this.currentDateId = null;

        this.updateDate();
      }, timeout);
    },
    setNextDateDraw(startTime) {
      const timestampValid = (new Date(startTime).getTime() > 0);
      if (!timestampValid) {
        this.nextRaffle = '00:00:00';
        return this.nextRaffle;
      }

      const currentDate = Date.now();
      const timestamp = new Date(startTime).getTime();
      if (timestamp <= currentDate) {
        this.nextRaffle = '00:00:00';
        return this.nextRaffle;
      }

      if (startTime === this.nextRaffleTimestamp) {
        return this.nextRaffle;
      }
      const date = new Date(startTime);
      let h = date.getHours();
      let m = date.getMinutes();
      let s = date.getSeconds();
      h = (h >= 10) ? h : `0${h}`;
      m = (m >= 10) ? m : `0${m}`;
      s = (s >= 10) ? s : `0${s}`;

      const dateDraw = `${h}:${m}:${s}`;
      this.nextRaffle = dateDraw;
      this.nextRaffleTimestamp = startTime;
      return dateDraw;
    },
    getPhrase(phraseId) {
      const phrase = APP_UTILS.getPhrase(phraseId);
      return phrase;
    },
    // eslint-disable-next-line no-unused-vars
    validateLockJackpot(startGame) {
      this.lockJackpot = (this.totalBallsDisplayed > startGame.accumulatedPrizeBalls);
    },
  },
};
</script>

<template>
  <div class="containerRight">
    <v-row no-gutters>
      <div class="containerDrawInfo">
        <div class="drawInfoTitle text-subtitleLarge text-bold text-center colorOrange">
          {{getPhrase('currentRaffle')}}
        </div>
        <div class="drawInfoValue">
          <v-col cols="5" class="pa-0">
            <div class="drawInfoItem text-subtitle text-bold colorBlack">
              {{getPhrase('number')}}
              <br />
              {{getPhrase('value')}}
            </div>
          </v-col>
          <v-col cols="7" class="pa-0">
            <div class="drawInfoItem text-subtitle text-bold text-center colorWhite">
              {{currentRaffleData.matchId}}
              <br />
              <span class="colorOrange">{{currentRaffleData.cardValue}}</span>
            </div>
          </v-col>
        </div>
      </div>
    </v-row>
    <v-row no-gutters>
      <div
        class="backPrize"
        v-for="(prize, index) in showPrize"
        v-bind:key="index"
      >
        <v-col
          class="pa-0 mb-1">
          <div :class="`d-flex backPrize_${index} ${prize.shown} ${prize.showAnimation || ''}`">
            <v-col cols="2" class="pa-1 ml-1">
              <div>
                <v-img
                  width="90%"
                  height="100%"
                  :class="`${prize.disable}`"
                  :id="'trophy-' + index"
                  src="../assets/image/trophy.png"
                />
              </div>
            </v-col>
            <v-col cols="9" class="px-0 textPrize">
              <div class="text-bold colorWhite boxText">
                {{prize.name}}
              </div>
              <v-spacer />
              <div :class="`text-bold colorOrange ${prize.greyColor}`">
                {{prize.value}}
              </div>
            </v-col>
          </div>
        </v-col>
      </div>
    </v-row>
    <v-row
      no-gutters
      v-if="participants.length > 0"
    >
      <div class="containerWinners">
        <v-col class="pa-0">
          <div class="winnersHeader text-subtitleLarge text-bold text-center colorOrange">
            {{getPhrase('participants')}}
          </div>
          <div
            v-for="(participant, index) in participants"
            v-bind:key="index"
          >
            <div
              :id="'row_' + index"
              :key="index"
              :class="(index >= participants.length - 1) ? 'winnersFooter':'winnersRow'"
            >
              <v-col cols="3" class="pa-0 ml-1 textLeft textUserId boxText">
                <div class="text-subtitle-medium colorWhite textFontNormal">
                  {{participant.userId}}
                </div>
              </v-col>
              <v-col cols="3" class="pa-0 textLeft textUserId boxText">
                <div class="text-subtitle-small colorOrange">
                  {{participant.cardId}}
                </div>
              </v-col>
              <v-col cols="6" class="pa-0 ml-0 containerCardValue text-center">
                <div
                  v-for="(value, indexCard) in participant.missingNumbers.slice(0,6)"
                  :key="indexCard"
                  class="cardValue"
                >
                  <div>
                    {{(value > 9) ? value : `0${value}`}}
                  </div>
                </div>
                <div
                  class="cardValue"
                  v-if="participant.position !== 'BI'"
                >
                  {{participant.position}}
                </div>
              </v-col>
            </div>
          </div>
        </v-col>
      </div>
    </v-row>
  </div>
</template>

<script>
import APP_UTILS from '../config/app_utils';
import APP_CONFIG from '../config/app_config';
/* eslint-disable no-console */
/* eslint-disable no-plusplus */

export default {
  name: 'InfoBingo',
  data() {
    return {
      ballsInBoard: 90,
      indexPrizes: 0,
      nextPrizesData: [],
      showPrize: [],
      currentDateId: null,
      currentPrizeId: null,
      isTvBox: false,
    };
  },
  beforeMount() {
    this.isTvBox = this.$store.getters.getChannel;
  },
  computed: {
    currentRaffleData() {
      const raffleData = this.$store.getters.currentRaffleData;
      if (typeof raffleData.matchId === 'undefined') {
        this.clearData();
      }
      this.changePrizeData(raffleData.nextPrizes);
      return raffleData;
    },
    awardedPrizes() {
      const awarded = [...this.$store.getters.awardedPrizes];
      return awarded;
    },
    participants() {
      return this.$store.getters.participants;
    },
  },
  methods: {
    updatePrize() {
      const maxPrize = 3;

      if (this.nextPrizesData && this.nextPrizesData.length && this.showPrize.length > maxPrize) {
        const nextPrizes = [...this.nextPrizesData];
        let startIndex = this.indexPrizes;
        let endIndex = startIndex + maxPrize;
        let showPrize = nextPrizes.slice(startIndex, endIndex);

        if (showPrize.length < maxPrize) {
          startIndex = 0;
          endIndex = maxPrize - showPrize.length;
          const diffItems = nextPrizes.slice(startIndex, endIndex);
          showPrize = [...showPrize, ...diffItems];

          const timeout = 3000;
          clearTimeout(this.currentPrizeId);
          this.currentPrizeId = null;
          // Recursive function
          this.currentPrizeId = setTimeout(this.updatePrize, timeout);
        }

        this.showPrize = showPrize;
        this.indexPrizes = endIndex;
      } else {
        this.showPrize = [...this.nextPrizesData];
      }
    },
    changePrizeData(nextPrizes) {
      if (typeof nextPrizes === 'undefined' || (nextPrizes?.length === 0 && this.awardedPrizes?.length === 0)) {
        return;
      }

      let newNextPrizes = nextPrizes.filter((p) => p.prizeId !== APP_CONFIG.PRIZE_ID_JACKPOT);
      if (newNextPrizes.length) {
        const style = (this.isTvBox)
          ? 'showCurrentPrize currentPrizeWithoutAnimation' : 'showCurrentPrize pulseAnimation';
        newNextPrizes[0].showAnimation = style;
      }

      // validate the prizes shown
      if (this.awardedPrizes && this.awardedPrizes.length) {
        let awardedPrizes = [...this.awardedPrizes];
        awardedPrizes = awardedPrizes.filter((prize) => {
          if (prize.prizeId === APP_CONFIG.PRIZE_ID_BINGO && prize.hasJackpot) {
            return false;
          }

          const parsePrize = prize;
          parsePrize.value = APP_UTILS.formatCurrency(prize.originalValue);
          parsePrize.shown = 'backPrizeShown';
          parsePrize.disable = 'trophyOpacity';
          parsePrize.greyColor = 'colorGrey';
          return parsePrize;
        });
        const matchPrize = [...newNextPrizes, ...awardedPrizes];
        newNextPrizes = matchPrize.sort((a, b) => a.prizeId - b.prizeId);
      }

      this.nextPrizesData = newNextPrizes;
      clearTimeout(this.currentPrizeId);
      this.currentPrizeId = null;
      this.updatePrize();
    },
    clearData() {
      this.nextPrizesData = [];
      this.showPrize = [];
      clearTimeout(this.currentPrizeId);
      this.currentPrizeId = null;
    },
    getPhrase(phraseId) {
      const phrase = APP_UTILS.getPhrase(phraseId);
      return phrase;
    },
  },
};
</script>

<template>
  <div class="appTvBingo">
    <BingoView />
    <IdleApp
      v-if="showIdle"
    />
    <DialogError
      v-if="showError"
    />
    <DialogFullscreen />
    <div
      v-if="isTvBox || demoMode"
      class="flagVersionTest colorBlack"
    >
      <span v-if="demoMode"> DEMONSTRAÇÃO | </span>
      <span v-if="isTvBox"> TV BOX </span>
      <span> | v1.8.6 </span>
    </div>
  </div>
</template>

<script>
import IdleApp from '../components/idle.vue';
import BingoView from './bingoView.vue';
import DialogFullscreen from '../components/DialogFullscreen.vue';
import DialogError from '../components/DialogError.vue';
import constants from '../constants/constants';

export default {
  name: 'TvView',
  components: {
    IdleApp,
    BingoView,
    DialogFullscreen,
    DialogError,
  },
  data() {
    return {
      isTvBox: false,
      demoMode: false,
    };
  },
  mounted() {
    this.setDataURL();
    this.isTvBox = this.$store.getters.getChannel;
    this.demoMode = this.$store.getters.getDemoMode;
  },
  computed: {
    showIdle() {
      return this.$store.getters.showIdle;
    },
    fullscreenActive() {
      return !this.$store.getters.showIdle;
    },
    showError() {
      return this.$store.getters.showError;
    },
  },
  methods: {
    setDataURL() {
      const router = this.$router;
      const currentRoute = router && router.currentRoute;
      if (currentRoute && currentRoute.path) {
        this.$store.dispatch(constants.INITIALIZE_APP, currentRoute.path);
      }
    },
  },
};
</script>

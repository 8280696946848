import languagePT from '../languages/language_PT';

const APP_UTILS = {
  formatCurrency: (number) => {
    const options = { style: 'currency', currency: 'BRL' };
    const numberFormat = new Intl.NumberFormat('pt-BR', options);

    return numberFormat.format(number);
  },

  getPhrase: (phraseId, lowerCase) => {
    const upperCase = lowerCase || true;
    let phrase = '';
    // eslint-disable-next-line no-prototype-builtins
    if (languagePT.hasOwnProperty(phraseId)) {
      phrase = languagePT[phraseId];

      if (upperCase) {
        phrase = phrase.toLocaleUpperCase();
      }
    }

    return phrase;
  },
};

export default APP_UTILS;

<template>
  <div
    class="containerFS_Dialog"
    v-if="showDialog"
  >
    <div
      class="backgroundChannel
      backgroundBingo
      zoomInAnimation"
    >
      <div class="containerChannel">
        <div class="titleChannel text-large colorBlack">
          {{title}}
        </div>
        <div class="buttonsContainer">
          <div class="buttonChannel">
            <div
              class="imageChannel imageDesktop"
              v-on:click="() => setChannel(false)"
            />
            <div class="text-subtitle-large colorBlack">
              {{optionDesktop}}
            </div>
          </div>
          <div class="buttonChannel">
            <div
              class="imageChannel imageTv"
              v-on:click="() => setChannel(true)"
            />
            <div class="text-subtitle-large colorBlack">
              {{optionTv}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import constants from '../constants/constants';
import APP_TEXTS from '../languages/language_PT';
import '../css/dialogChannel.css';

export default {
  name: 'DialogChannel',
  data() {
    return {
      leaveFS_timeoutId: null,
      message: APP_TEXTS.fullscreenMessage,
      optionDesktop: APP_TEXTS.typeComputer,
      optionTv: APP_TEXTS.typeTv,
      showDialog: true,
      title: APP_TEXTS.typeChannel,
    };
  },
  methods: {
    goToFullscreen() {
      if (document.documentElement && document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
          .catch(() => {
            this.hideDialog();
          });
      }
      this.hideDialog();
    },
    hideDialog() {
      this.showDialog = false;
    },
    setChannel(boolean) {
      this.$store.dispatch(constants.SET_CHANNEL, boolean);
      this.goToFullscreen();
    },
  },
};
</script>

<template>
  <v-app>
    <DialogChannel
      v-if="!channelSelected"
    />
    <TvView
      v-if="showTvView"
    />
  </v-app>
</template>

<script>
import TvView from './view/tvView.vue';
import DialogChannel from './components/DialogChannel.vue';
import constants from './constants/constants';

const spritesheetCoins = () => import('./css/spritesheet_coins.css');
const spritesheet = () => import('./css/spritesheet.css');

export default {
  name: 'App',
  components: {
    TvView,
    DialogChannel,
  },
  data() {
    return {
      showTvView: false,
    };
  },
  watch: {
  },
  beforeMount() {
    this.validateIsApp();
  },
  computed: {
    channelSelected() {
      const channelSelected = this.$store.getters.getChannelSelected;
      if (channelSelected) {
        this.loadAssets();
      }

      return channelSelected;
    },
  },
  methods: {
    loadAssets() {
      const isTvBox = this.$store.getters.getChannel;
      const isApp = this.$store.getters.getIsApp;
      if (!isTvBox || !isApp) {
        spritesheet();
        spritesheetCoins();
      }

      this.showTvView = true;
    },
    validateIsApp() {
      this.$store.dispatch(constants.IS_APP);
    },
  },
};
</script>

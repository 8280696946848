import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';
import vuetify from './plugins/vuetify';
import App from './App.vue';

import './css/bingoStyle.css';

Vue.config.productionTip = false;
Vue.use(VueRouter);

const routerParams = {
  mode: 'history',
  base: '/',
  routes: [
    { path: '/operator/:id', redirect: '/' },
    { path: '/table/:id', redirect: '/' },
    { path: '/operator/:id/table/:tableId', redirect: '' },
    { path: '/operator/:id/table/:tableId/slow/:slowActive', redirect: '' },
  ],
};

const router = new VueRouter(routerParams);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

import Vue from 'vue';
import Vuex from 'vuex';
import CommManager from '../api/commManager';
import constants from '../constants/constants';

Vue.use(Vuex);

const location = window && window.location;
const originURL = location && location.origin;
const commManager = new CommManager();

export default new Vuex.Store({
  state: {
    ads: {},
    awardedPrizes: [],
    currentRaffleData: {
      accumulatedPrize: 0,
      accumulatedPrizeBalls: 0,
      cardValue: 0,
      closeBetsTime: 0,
      matchId: 0,
      matchName: '',
      nextDateDraw: {},
      nextPrizes: [],
      nextPrizesToPreRaffle: [],
      startTime: 0,
      timestamp: '',
    },
    gameInProcess: false,
    initializedApp: false,
    showAnticipation: false,
    showFuturePlays: false,
    showGame: false,
    showIdle: true,
    showingPrize: false,
    showError: false,
    statusPlay: '',
    startGame: {
      accumulatedPrize: 0,
      accumulatedPrizeBalls: 0,
      currentBall: 0,
      currentPositionRecovered: 0,
      idleResults: [],
      isRecoverRaffle: false,
      /* DELAYED GAME, IT WILL BE DEPRECATED */
      isDelayedGame: false,
      results: [],
    },
    upcomingDraw: [],
    previousRaffles: [],
    winners: [],
    participants: [],
    channelSelected: false,
    isTvBox: false,
    isMute: false,
    demoMode: false,
    isApp: false,
    originURL,
    messageError: {
      type: '',
      component: '',
      content: '',
      show: false,
    },
  },
  mutations: {
    setDataFromServer(state, data) {
      if (!data) return;

      const currentRaffleData = { ...state.currentRaffleData, ...data.currentRaffleData };
      const startGame = { ...state.startGame, ...data.startGame };
      if (startGame.results && !startGame.results.length) {
        startGame.idleResults = [];
        startGame.isDelayedGame = false;
      }

      if (state.statusPlay === constants.STATE_DRAW_RAFFLE_COMPLETE
        || state.statusPlay === constants.STATE_DRAW_PLAY_CLOSED) {
        startGame.idleResults = [];
        startGame.isDelayedGame = false;
      }

      state.winners = data.winners || [];
      state.participants = data.participants || [];
      state.statusPlay = data.statusPlay;
      state.awardedPrizes = data.awardedPrizes || [];
      state.currentRaffleData = currentRaffleData;
      state.startGame = startGame;
      state.showIdle = false;
      state.showError = false;
    },
    setUpcomingDraw(state, data) {
      state.upcomingDraw = data;
      state.nextDateDraw = data && data.length && data[0];
      state.showIdle = false;
      state.showError = false;
    },
    setPreviousRaffles(state, data) {
      state.previousRaffles = data;
    },
    setInitializedApp(state, param) {
      state.initializedApp = param;
    },
    setChannel(state, param) {
      state.channelSelected = true;
      state.isTvBox = param;
    },
    setNextDateDraw(state, param) {
      state.currentRaffleData.nextDateDraw = param;
    },
    setMessageError(state, param) {
      const newError = { ...state.messageError, ...param };
      state.messageError = newError;
      state.showError = newError.show;
    },
    setNewIdleBall(state, idBall) {
      state.startGame.idleResults.push(idBall);
    },
    setIsApp(state) {
      const origin = state.originURL;
      const param = origin.split('tvbingoapp');
      const isApp = (param && param.length && param.length >= 2) || false;
      state.isApp = isApp;
      state.channelSelected = isApp;
      state.isTvBox = isApp;
      // validate if demo version is active
      if (origin.includes('localhost') || origin.includes('logo')) {
        state.demoMode = true;
      }
    },
    setActiveMute(state, param) {
      state.isMute = param;
    },
    setAdsData(state, data) {
      state.ads = data;
    },
    setRecoverRaffle(state, recovered) {
      state.startGame.isRecoverRaffle = recovered;
    },
    setShowingPrize(state, showing) {
      state.showingPrize = showing;
    },
  },
  getters: {
    upcomingDraw(state) {
      return state.upcomingDraw.slice(0, 10);
    },
    nextDraw(state) {
      return state.currentRaffleData.nextDateDraw;
    },
    currentRaffleData(state) {
      return state.currentRaffleData;
    },
    currentRaffle(state) {
      return state.currentRaffleData;
    },
    awardedPrizes(state) {
      return state.awardedPrizes;
    },
    winners(state) {
      return state.winners;
    },
    participants(state) {
      return state.participants.slice(0, 16);
    },
    startGame(state) {
      return state.startGame;
    },
    previousRaffles(state) {
      return state.previousRaffles;
    },
    showIdle(state) {
      return state.showIdle;
    },
    showFuturePlays(state) {
      return (state.statusPlay === constants.STATE_DRAW_PLAY_OPEN);
    },
    showCompletedGame(state) {
      return (state.statusPlay === constants.STATE_DRAW_RAFFLE_COMPLETE
        || state.statusPlay === constants.STATE_DRAW_PLAY_CLOSED);
    },
    showGame(state) {
      return (state.statusPlay === constants.STATE_DRAW_RAFFLING);
    },
    showAnticipation(state) {
      return (state.statusPlay === constants.STATE_DRAW_ACCEPTING_BETS
        || state.statusPlay === constants.STATE_DRAW_PLAY_OPEN
        || state.statusPlay === constants.STATE_DRAW_NO_MORE_BETS);
    },
    stateRaffling(state) {
      return state.statusPlay === constants.STATE_DRAW_RAFFLING;
    },
    showError(state) {
      return state.showError;
    },
    showMessageError(state) {
      return state.messageError.show;
    },
    statusPlay(state) {
      return state.statusPlay;
    },
    getMessageError(state) {
      return state.messageError;
    },
    getChannelSelected(state) {
      return state.channelSelected;
    },
    getChannel(state) {
      return state.isTvBox;
    },
    getDemoMode(state) {
      return state.demoMode;
    },
    getIsApp(state) {
      return state.isApp;
    },
    getActiveMute(state) {
      return state.isMute;
    },
    getAds(state) {
      return state.ads;
    },
    getRecoverRaffle(state) {
      return state.startGame.isRecoverRaffle;
    },
    /* DELAYED GAME, IT WILL BE DEPRECATED */
    isDelayedGame(state) {
      return state.startGame.idleResults.length > 0
        && state.startGame.results.length >= state.startGame.idleResults.length + 2;
    },
    showingPrize(state) {
      return state.showingPrize;
    },
  },
  actions: {
    initializeApp(context, urlParams) {
      commManager.initialize(this, urlParams);
      context.commit(constants.SET_INITIALIZED_APP, true);
    },
    isApp(context) {
      context.commit(constants.SET_IS_APP);
    },
    activeMute(context, data) {
      context.commit(constants.SET_ACTIVE_MUTE, data);
    },
    updateDataFromServer(context, data) {
      context.commit(constants.SET_DATA_FROM_SERVER, data);
    },
    updateDataUpcomingDraw(context, data) {
      context.commit(constants.SET_UPCOMING_DRAW, data);
      const nextDraw = (data.length) ? data[0].startTime : {};
      context.commit(constants.SET_NEXT_DATE_DRAW, nextDraw);
    },
    updateDataPreviousDraw(context, data) {
      context.commit(constants.SET_PREVIOUS_RAFFLES, data);
    },
    showErrorMessage(context, param) {
      context.commit(constants.SET_MESSAGE_ERROR, param);
    },
    setChannel(context, param) {
      context.commit(constants.SET_CHANNEL, param);
    },
    addNewIdleBall(context, idBall) {
      context.commit(constants.SET_NEW_IDLE_BALL, idBall);
    },
    addAds(context, data) {
      context.commit(constants.SET_ADS_DATA, data);
    },
    updateRecoverGame(context, param) {
      commManager.updateIsRecoverRaffle(param);
      context.commit(constants.SET_RECOVER_RAFFLE, param);
    },
    updateShowingPrize(context, showing) {
      context.commit(constants.SET_SHOWING_PRIZE, showing);
    },
  },
  modules: {
  },
});

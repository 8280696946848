<template>
  <div class="futurePlaysContainer backgroundBingo">
    <div class="preRaffleTitle">
      <div class="text-title-big text-bold text-center colorOrange">
        {{getPhrase('nextRaffles')}}
      </div>
    </div>
    <div class="preRaffleInfo">
      <table
        class="table"
        v-if="upcomingDraw.length > 0"
      >
        <thead class="tableHeader">
          <tr>
            <td
              v-for="item in headers"
              :key="item.name"
              class="text-subtitle-3 colorWhite text-center"
            >
              {{ item.text }}
            </td>
            <td
              v-for="(item, index) in prizeToShow"
              :key="item.name"
              class="text-subtitle-3 colorWhite text-center"
            >
              {{ `${index + 1}° ${getPhrase('prize', true)}` }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(play, index) in upcomingDraw"
            :key="index"
            :class='(index % 2) ? "tableRowLight textFontNormal" : "tableRowDark textFontNormal"'
          >
            <td>
              <span class="text-subtitle-large colorWhite text-center">
                {{ play.matchName }}
              </span>
              <br />
              <span class="text-subtitle-medium colorWhite text-center">
                {{ play.date}}
              </span>
            </td>
            <td class="text-subtitle-3 colorWhite text-center">
              {{ play.startTime }}
            </td>
            <td class="text-subtitle-3 colorWhite text-center">
              {{ play.cardValue }}
            </td>
            <td
              v-for="(prize, prizeIndex) in play.prizeToShow"
              :key="prizeIndex"
            >
              <span class="prizeFuturePlays text-subtitle-large colorWhite text-center">
                {{ setFormatCurrency(prize.value) }}
              </span>
              <span class="prizeFuturePlays text-subtitle-medium colorOrange text-center">
                {{ prize.name }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="progressContainer"
        v-else-if="!showMessageNotFound"
      >
        <v-progress-circular
          :size="100"
          :width="7"
          color="white"
          indeterminate
        ></v-progress-circular>
      </div>
      <div
        v-else
        class="text-subtitle-large textLeft colorBlack"
      >
        {{getPhrase('futurePlaysNotAvailable')}}
      </div>
    </div>
  </div>
</template>

<script>
import APP_UTILS from '../config/app_utils';
import APP_CONFIG from '../config/app_config';
import APP_TEXTS from '../languages/language_PT';
/* eslint-disable no-console */
/* eslint-disable no-plusplus */

export default {
  name: 'FuturePlays',
  data() {
    return {
      headers: [
        { text: APP_TEXTS.raffle, align: 'start' },
        { text: APP_TEXTS.hour, align: 'center' },
        { text: APP_TEXTS.value, align: 'center' },
      ],
      futurePlaysData: [],
      prizeToShow: [],
      errorDataFuturePlays: 0,
      showMessageNotFound: false,
      errorData_timeoutId: null,
    };
  },
  computed: {
    upcomingDraw() {
      const upcoming = this.$store.getters.upcomingDraw;
      const futurePlays = this.futurePlaysParse(upcoming);
      return futurePlays;
    },
  },
  methods: {
    futurePlaysParse(playsData) {
      const futurePlays = [];

      for (let index = 0; index < playsData.length; index++) {
        const play = playsData[index];
        const timestampValid = ((new Date(play.startTime)).getTime() > 0);
        let timeDraw = '00:00:00';
        let dateDraw = '00/00/0000';
        if (timestampValid) {
          const date = new Date(play.startTime);
          let day = date.getDate();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();
          let h = date.getHours();
          let m = date.getMinutes();
          let s = date.getSeconds();
          day = (day >= 10) ? day : `0${day}`;
          month = (month >= 10) ? month : `0${month}`;
          year = (year >= 10) ? year : `0${year}`;
          h = (h >= 10) ? h : `0${h}`;
          m = (m >= 10) ? m : `0${m}`;
          s = (s >= 10) ? s : `0${s}`;

          timeDraw = `${h}:${m}:${s}`;
          dateDraw = `${day}/${month}/${year}`;
        }

        const prizes = play.prizesById;
        const lineId = String(APP_CONFIG.PRIZE_ID_LINHA);
        const quadraId = String(APP_CONFIG.PRIZE_ID_QUADRA);
        const bingoId = String(APP_CONFIG.PRIZE_ID_BINGO);
        const prizeToShow = [];

        Object.entries(prizes).forEach(([key, prize]) => {
          if (key === lineId || key === quadraId || key === bingoId) {
            const prizeToAdd = { ...prize };
            const value = prizeToAdd.value || 0;
            prizeToAdd.value = APP_UTILS.formatCurrency(value);
            prizeToShow.push(prize);
          }
        });

        this.prizeToShow = (prizeToShow.length) ? prizeToShow : [];

        const newPlay = {
          startTime: timeDraw,
          matchName: play.matchName,
          date: dateDraw,
          prizeToShow,
          cardValue: APP_UTILS.formatCurrency(play.cardValue),
        };

        futurePlays.push(newPlay);
      }

      this.setError(futurePlays);

      this.futurePlaysData = futurePlays;
      return futurePlays;
    },
    setError(futurePlays) {
      if (futurePlays.length) {
        this.errorDataFuturePlays = 0;
        this.showMessageNotFound = false;
      } else {
        this.errorDataFuturePlays += 1;
        this.showMessageNotFound = (this.errorDataFuturePlays >= 10);
        this.validateError();
      }
    },
    validateError() {
      const timeout = 1000;
      clearTimeout(this.errorData_timeoutId);
      this.errorData_timeoutId = null;

      if (!this.prizeToShow.length && this.errorDataFuturePlays < 10) {
        this.errorData_timeoutId = setTimeout(this.validateError, timeout);
      }
    },
    getPhrase(phraseId, lowerCase) {
      const phrase = APP_UTILS.getPhrase(phraseId, lowerCase);
      return phrase;
    },
    setFormatCurrency(value) {
      return APP_UTILS.formatCurrency(value);
    },
  },
};
</script>

<template>
  <div class="containerFS_Dialog">
    <div
      class="backgroundFS_Dialog
      backgroundBingo
      zoomInAnimation"
    >
      <div class="titleDialogError text-xlarge colorBlack">
        {{title}}
      </div>
    </div>
  </div>
</template>

<script>
import APP_TEXTS from '../languages/language_PT';

export default {
  name: 'DialogClosePlay',
  data() {
    return {
      title: APP_TEXTS.RaffleComplete,
    };
  },
  methods: {
  },
};
</script>

<template>
  <div class="preRaffleContainer backgroundBingo">
    <div
      id="preRaffleComponent"
      v-show="components[0].show"
    >
      <div class="preRaffleTitle">
        <div class="text-title-big text-bold text-center colorOrange">
          {{getPhrase('attention')}}
        </div>
      </div>
      <div class="">
        <div class="preRaffleInfo text-large text-bold colorBlack">
          {{(showTitleText) ? this.getPhrase('raffleToStart') : raffleToStartTitle}}
          <div class="preRaffleProgress">
            <v-progress-linear
              color="light-green darken-4"
              height="10"
              indeterminate
              rounded
              striped
              aria-progressbar-name="progressbar"
            ></v-progress-linear>
          </div>
        </div>
        <div class="preRaffleInfo text-large text-bold text-center colorWhite">
            {{`${currentRaffleData.matchId} - ${currentRaffleData.matchName}`}}
        </div>
        <div
          v-if="nextRaffle !== ''"
          class="preRaffleInfoStatus text-center"
        >
          <div
            class="preRaffle_status text-large text-bold colorWhite"
          >
            {{nextRaffle}}
          </div>
          <div
            class="
            preRaffle_status
            text-large text-bold colorOrange
            pulseAnimation"
          >
            {{getPhrase(statusPlay)}}
          </div>
        </div>
        <div
          v-else
          class="preRaffleInfoStatus text-center"
        >
          <div
            class="
            preRaffle_status_fullRow
            text-large text-bold colorOrange
            pulseAnimation"
          >
            {{getPhrase(statusPlay)}}
          </div>
        </div>
      </div>
      <div class="preRaffleInfoPrize">
        <div
          class="prizesContainer"
          v-if="currentRaffleData.nextPrizesToPreRaffle.length > 0"
        >
          <div
            class="prizeContainer"
            v-for="(prize, index) in currentRaffleData.nextPrizesToPreRaffle"
            v-bind:key="index"
          >
            <div class="preRaffle_trophy">
              <v-img
                width="45%"
                height="100%"
                :id="'trophy-' + index"
                src="../assets/image/trophy_big.png"
                class="preRaffle_trophy"
              />
            </div>
            <div class="preRaffle_textPrize text-bold colorBlack boxText">
              {{prize.name}}
            </div>
            <div>
              <div class="preRaffle_textPrize text-bold colorGreen textCenter">
                {{prize.value}}
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="preRaffle_PrizesNotAvailable text-subtitle-large textLeft colorBlack"
        >
          {{getPhrase('prizeNotAvailable')}}
        </div>
        <div class="preRaffleAcc">
          <div class="preRaffleInfoAcc text-bold colorBlack">
            {{getPhrase('value')}}
            <br />
            {{currentRaffleData && currentRaffleData.cardValue}}
          </div>
          <div class="preRaffleInfoAcc text-bold colorBlack">
            {{getPhrase('accumulated')}}
            <br />
            {{currentRaffleData && currentRaffleData.accumulatedPrize}}
          </div>
          <div class="preRaffleInfoAcc text-bold colorBlack">
            {{`${getPhrase('until')} ${getPhrase('balls')}`}}
            <br />
            {{currentRaffleData && currentRaffleData.accumulatedPrizeBalls}}
          </div>
        </div>
      </div>
    </div>
    <div
      id="previousRafflesComponent"
      class="futurePlaysMain"
      v-show="components[1].show"
    >
      <PreviousRaffles />
    </div>
    <div
      id="futurePlaysComponent"
      class="futurePlaysMain"
      v-show="components[2].show"
    >
      <FuturePlays />
    </div>
    <div
      id="advertisingContainer"
      class="futurePlaysMain"
      v-if="components[3].show"
    >
      <CarrosselImage :time="raffleToStartTitle" />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-mixed-operators */
/* eslint-disable radix */
import APP_UTILS from '../config/app_utils';
// import APP_CONFIG from '../config/app_config';
import FuturePlays from './FuturePlays.vue';
import PreviousRaffles from './PreviousRaffles.vue';
import CarrosselImage from './Carrossel.vue';

export default {
  name: 'PreRaffle',
  data() {
    return {
      countDownText: '',
      currentComponentID: 0,
      currentComponentShown: 'preRaffle',
      errorDataPrize: 0,
      nextPrizes: [],
      nextRaffle: '',
      nextRaffleTimestamp: null,
      previousExtraComponentShown: '',
      raffleToStartTitle: '',
      rafflingState: this.$store.getters.stateRaffling,
      showFuturePlays: false,
      showFuturePlaysId: null,
      showingCounter: false,
      showMessageNotFound: false,
      showPreviousRaffles: false,
      showTitle_timeoutId: null,
      showComponent_timeoutId: null,
      showTitleText: true,
      timestampCounter: 0,
      updateCounterId: null,
      components: [
        {
          name: 'preRaffle',
          timeToShow: 20000, // milliseconds
          show: true,
        },
        {
          name: 'PreviousRaffles',
          timeToShow: 20000, // milliseconds
          show: false,
        },
        {
          name: 'FuturePlays',
          timeToShow: 20000, // milliseconds
          show: false,
        },
        {
          name: 'advertising',
          timeToShow: 20000, // milliseconds
          show: false,
        },
      ],
    };
  },
  components: {
    FuturePlays,
    PreviousRaffles,
    CarrosselImage,
  },
  computed: {
    statusPlay() {
      const statusTable = this.$store.getters.statusPlay;
      return statusTable;
    },
    currentRaffleData() {
      const raffleData = this.$store.getters.currentRaffleData;
      if (raffleData.closeBetsTime > 0 && !this.rafflingState) {
        this.startTime(raffleData.closeBetsTime);
        this.setNextDateDraw(raffleData.startTime);
      } else {
        this.clearData();
      }

      return raffleData;
    },
  },
  mounted() {
    this.showComponent();
  },
  methods: {
    getPhrase(phraseId) {
      const phrase = APP_UTILS.getPhrase(phraseId);
      return phrase;
    },
    startTime(closeBetsTime) {
      const parseTime = (new Date().getTime() + 1000 * closeBetsTime);
      const now = new Date().getTime();
      const diff = (parseTime - now) / 1000; // diff is the total number of seconds remaining

      if (!this.showingCounter) {
        if (diff) {
          this.showingCounter = true;
          this.timestampCounter = diff;
          this.showFuturePlays = true;
          this.updateCounter();
          this.showTitleAndCounter();
        } else {
          this.raffleToStartTitle = this.getPhrase('raffleToStart');
        }
      }
    },
    updateCounter() {
      clearTimeout(this.updateCounterId);
      this.updateCounterId = null;

      if (this.timestampCounter > 0 && this.showingCounter && !this.rafflingState) {
        const timeout = 1000;
        const hours = parseInt(this.timestampCounter / 60 / 60 % 24);
        const minutes = parseInt(this.timestampCounter / 60 % 60);
        const seconds = parseInt(this.timestampCounter % 60);

        if (hours === 0 && minutes === 0) {
          this.showFuturePlays = false;
          this.showPreviousRaffles = false;
          this.showTitleText = false;
        }

        const hoursTxt = (hours >= 10) ? hours : `0${hours}`;
        const minutesText = (minutes >= 10) ? minutes : `0${minutes}`;
        const secondsText = (seconds >= 10) ? seconds : `0${seconds}`;

        this.timestampCounter -= 1;
        this.raffleToStartTitle = `${hoursTxt}:${minutesText}:${secondsText}`;
        this.playSound(hours, minutes, seconds);

        this.updateCounterId = setTimeout(this.updateCounter, timeout);
      } else {
        this.raffleToStartTitle = this.getPhrase('preparingRaffle');
      }
    },
    showComponent() {
      let time = 20000;
      const currentIndex = this.currentComponentID;
      const adsData = this.$store.getters.getAds;
      const imgs = adsData.imagens || [];

      clearTimeout(this.showComponent_timeoutId);
      // eslint-disable-next-line max-len
      this.currentComponentID = ((currentIndex < this.components.length - 1) && (this.timestampCounter > 90)) ? currentIndex + 1 : 0;

      if (this.currentComponentID === 3 && imgs.length === 0) {
        this.currentComponentID = 0;
      }

      this.components.forEach((component, index) => {
        // eslint-disable-next-line no-param-reassign
        component.show = (this.currentComponentID === index);

        if (this.currentComponentID === index) {
          time = (this.currentComponentID === 3 && adsData.totalTempoExibicao > 0)
            ? adsData.totalTempoExibicao
            : component.timeToShow;
        }
      });

      // eslint-disable-next-line max-len
      this.showComponent_timeoutId = setTimeout(this.showComponent, time);
      return true;
    },
    showTitleAndCounter() {
      const hours = parseInt(this.timestampCounter / 60 / 60 % 24);
      const minutes = parseInt(this.timestampCounter / 60 % 60);

      clearTimeout(this.showTitle_timeoutId);
      this.showTitle_timeoutId = null;

      if (hours >= 0 && minutes >= 1 && !this.rafflingState) {
        const time = (this.showTitleText) ? 10000 : 2000;
        this.showTitleText = !this.showTitleText;
        this.showTitle_timeoutId = setTimeout(this.showTitleAndCounter, time);
      }
    },
    setNextDateDraw(startTime) {
      const timestampValid = ((new Date(startTime)).getTime() > 0);
      if (!startTime || !timestampValid) {
        this.nextRaffle = '';
        return this.nextRaffle;
      }

      if (this.nextRaffle !== '') {
        return this.nextRaffle;
      }

      const date = new Date(startTime);
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
      };
      const dateDraw = new Intl.DateTimeFormat('pt-BR', options).format(date);

      this.nextRaffle = dateDraw.replace(',', ' -');
      this.nextRaffleTimestamp = startTime;

      return dateDraw;
    },
    getSound(nameSound, volume) {
      let urlSound = null;
      let audio = null;
      try {
        /* eslint-disable import/no-dynamic-require */
        /* eslint-disable global-require */
        urlSound = require(`../assets/sounds/${nameSound}.mp3`);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(`Error loading sound ${nameSound}.mp3`);
      }

      if (urlSound) {
        const volumeLevel = volume || 1;
        audio = new Audio(urlSound);
        audio.volume = volumeLevel;
      }

      return audio;
    },
    playSound(hours, minutes, seconds) {
      const hour = Number(hours);
      const min = Number(minutes);
      const sec = Number(seconds);
      let soundName = '';

      if (hour >= 1 && min > 10) {
        return;
      }

      if (min > 0 && min <= 10 && sec === 0) {
        soundName = `preraffle_${min}`;
      } else if (min === 0 && sec === 2) {
        soundName = 'noMoreBets';
      }

      if (soundName === '') return;

      const audio = this.getSound(soundName);
      if (audio) {
        audio.play();
      }
    },
    clearData() {
      this.nextPrizes = [];
      this.raffleToStartTitle = this.getPhrase('preparingRaffle');
      this.timestampCounter = 0;
      this.showingCounter = false;
      clearTimeout(this.updateCounterId);
      clearTimeout(this.showTitle_timeoutId);
      this.updateCounterId = null;
      this.showTitle_timeoutId = null;
    },
  },
};
</script>

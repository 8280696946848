const CONFIG_APP = {
  BASE_API: process.env.VUE_APP_BASE_URL_API,
  BASE_ADS_API: process.env.VUE_APP_BASE_URL_ADS_API,
  ENDPOINT_GET_NEXT_MATCHES: 'api/v2/next_matches/',
  ENDPOINT_GET_PREVIOUS_MATCHES: 'api/v2/last_matches_results/',
  ENDPOINT_RUNNING_TABLES: 'api/v2/get_table_for?gameCode=',
  ENDPOINT_TABLE_STATUS: 'api/v2/table_status/',
  TABLE_PREPROCESSED: '?preprocessed=true',
  ENDPOINT_GET_IMAGES: 'api/Integration/table/:table_id/get_propagandas',

  PRIZE_ID_LINHA: 1,
  PRIZE_ID_QUADRA: 2,
  PRIZE_ID_BINGO: 3,
  PRIZE_ID_JACKPOT: 4,

  PRIZE_NAME_LINHA: 'LINHA',
  PRIZE_NAME_QUADRA: 'QUADRA',
  PRIZE_NAME_BINGO: 'BINGO',
};

export default CONFIG_APP;
